@import url(https://fonts.googleapis.com/css?family=Sanchez|Fira+Sans);
html{
  /*background: url('../images/backdrop2.png');*/
  /*background: linear-gradient(-60deg,  rgb(121, 109, 155), rgb(190, 180, 210),rgb(121, 109, 155));*/
  /*background: linear-gradient(-30deg,  rgb(70, 8, 50), rgb(10, 10, 11),rgb(4, 4, 25));*/
  /*background: linear-gradient(-60deg,  rgb(110, 148, 101), rgb(180, 210, 187),rgb(110, 148, 101));*/
  /*background: linear-gradient(-40deg, rgb(147, 170, 78), rgb(194, 215, 129),rgb(129, 170, 41));
  background: linear-gradient(-20deg, rgb(85, 107, 24), rgb(255, 243, 105),rgb(78, 102, 17));
  background: linear-gradient(3deg, rgb(237, 135, 21), rgb(255, 246, 72),rgb(249, 145, 28));
  background: linear-gradient(3deg, rgb(70, 159, 127), rgb(135, 255, 230),rgb(65, 161, 154));
  background: linear-gradient(-70deg, rgb(0, 102, 143) 20%, rgb(0, 136, 191) 50%,rgb(0, 102, 143) 80%);
  /*background: linear-gradient(3deg, rgb(6, 0, 60), rgb(70, 144, 255),rgb(0, 106, 225));
  background: linear-gradient(3deg, rgb(0, 12, 80), rgb(80, 3, 255),rgb(222, 0, 255));*/
  /*
  background: -webkit-linear-gradient(160deg,#005441 20%,#006a52 50%,#005441 80%);
  background: linear-gradient(-70deg,#005441 20%,#006a52 50%,#005441 80%);
  */
  
  background: -webkit-linear-gradient(160deg,#3089ca 20%, #4099da 50%,#3089ca 80%);
  background: linear-gradient(-70deg,#3089ca 20%, #4099da 50%,#3089ca 80%);
  
  background-size: cover;
  color: white;
  box-sizing: border-box;
}

body{
  font-family: 'Fira Sans', sans-serif;
  overflow: hidden;
  margin: 0;
}

h3{
  font-size: 2vh;
  line-height: 2vh;
}

p{
  font-size: 1vh;
  line-height: 1vh;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.stand-in-text{
  font-size: 10vh;
  margin-top: 45vh;
  margin-left: 2%;
  position: absolute;
  width: 100%;
  font-family: orsted-font-regular;
}

.content{
  float: left;
}


.slide-show-2{
  left: 33vw;
}
.slide-show-1 {
  left: 7vw;
}

.slide-show {
  /*bottom: 3vh;*/
  position: absolute;   
  width: 44.5vh;/*23%;*/
  height: 25vh;/*25%;*/
  top: 71.7%;
  overflow: hidden;
  border-radius: 0.5vh;
  box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
}

.slide-show .slide img{
  width: 100%;
}


.slides{
  float: left;
  width: 95%;
  height: 33%;
}

.slides .temp-slide{
  box-sizing: border-box;
  height: 100%;
  width: 33.3%;
  padding: 2%;
  padding-right: 3%;
  padding-left: 3%;
  float: left;
}

.slides .temp-slide .slide{
  background: olive;
  width: 100%;
  height: 100%;
}

.slides .temp-slide .slide img{
  width: 100%;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

.numInputWrapper span.arrowUp, .numInputWrapper span.arrowDown{
  opacity: 1;
  width: 40px;
}

.numInputWrapper span.arrowUp::after, .numInputWrapper span.arrowDown::after{
  left: 37%;
}

.flatpickr-time .numInputWrapper input{
  padding-right: 33px;
}

.text-muted{
  color: #b5c0ce;
}

.text-sm{
  font-size: 0.8em;
}

.persons{
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.persons .person{
  position: relative;
  width: 3.7vw;
  margin-right: 0.8vw;
  margin-bottom: 1vw;
}

.persons .person img{
  width: 100%;
  border-radius: 100%;
}

.persons .person .person-name{
  position: absolute;
  font-size: 0.45vw;
  line-height: 0.45vw;
  bottom: -0.8vh;
  text-align: center;
  left: -20%;
  right: -20%;
}

@font-face {
    font-family: "orsted-font-light";
    src: url(/static/media/OrstedSans-Light.40d7d1f4.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-black";
    src: url(/static/media/OrstedSans-Black.919c32be.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-black-italic";
    src: url(/static/media/OrstedSans-BlackItalic.99c2dd72.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-bold";
    src: url(/static/media/OrstedSans-Bold.0359cd6e.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-bold-italic";
    src: url(/static/media/OrstedSans-BoldItalic.1cc81c74.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-italic";
    src: url(/static/media/OrstedSans-Italic.065116f3.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-light-italic";
    src: url(/static/media/OrstedSans-LightItalic.a47a5fb7.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-regular";
    src: url(/static/media/OrstedSans-Regular.ce818dbc.woff) format('woff');
}

@font-face {
    font-family: "orsted-font-semi-bold";
    src: url(/static/media/OrstedSans-SemiBoldText.b734b493.woff) format('woff');
}
/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  min-width: 1600px;
  min-height: 900px;
}

.slideshow-new {
  display: absolute;
  margin: 0 auto;
  overflow: hidden;
  min-width: 1600px;
  min-height: 900px;
}

.weather-item-container{
  justify-content: space-between;
  justify-content:center;
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  position: relative;
  width: 20vw;
  padding-bottom: 3%;
  padding-left: 10%;
}

.weather {
  
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 2vw;
  height: 22vh;
  align-items: center;
  padding-left: 12%;
  
}
.valueTemperatur {
  font-weight: bold;
  /* padding-right: 40%;
  padding-bottom: 0%;
  padding-top: 10%;
  top: 3.6vh; */
  position: absolute;
  top: 5%;
  right: 70%;
}

.value {
  font-weight: bold;
  /* margin-top: 0.8vh;
  padding-left: 0%; */
  position: absolute;
  top: 5%;
  left: 130%;

}
.valuePrecipitation{
  font-weight: bold;
  /* padding-left: 40%;
  width: 4vw;
  margin-top: 2vh; */
  width: 4vw;
  position: absolute;
  bottom: 10%;
  left: 30%;
}
.weatherCloudAndPrecipitation-icon-container{
  /* display: flex;
  flex-direction: column;
  align-items: center; 
  padding-left: 50%;
  margin-top: 0px;
  margin-bottom: 20%;
  width: 2vw;
  height: 11.55vh;
  z-index: 1; */
  width: 2vw;
  position: absolute;
  z-index: 1;
  top: 15%;
  left: -30%;
}

.wind-icon-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; 
  padding-right: 64%;
  margin-top: -2vh;
  z-index: 0; */
  position: absolute;
  z-index: 0;
  width: 6.5vw;
  top: -32%;
  left: 50%;
}
.icon{
  height: 19.444vh;
}



/* Weather.css */

.Weather {
  background-color: #ffffff;
  color: #000;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.weather-container {
  width: 86vw;
  height: 86vh;
  padding: 7vh 7vw 7vh 7vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.weatherCloudAndPrecipitation-icon {
  width: 8vw;
  padding-top: 70%;
}
.wind-icon {
  width: 6.5vw;
  padding-top: 100%;
}
.noDataForForecast-container{
  position: absolute;
  width: 30vw;
  height: 8vh;
  left: 35vw;
  top: 36vh;
}

.noDataForForecast{
  font-size: 7vh;
}
.Morgen{
  font-size: 1.5vw;
}
.Middag{
  font-size: 1.5vw;
}
.Aften{
  font-size: 1.5vw;
}
.dateMargin{
  font-size: 1.5vw;
  font-weight: bold;
}
.Vejretoverskrift{
  font-size: 3vw;
  position: relative;
  right: -2vw;
}
.vejrDataFraDmi{
  font-size:small;
  padding-left: 90%;
}

