.weather-item-container{
  justify-content: space-between;
  justify-content:center;
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  position: relative;
  width: 20vw;
  padding-bottom: 3%;
  padding-left: 10%;
}

.weather {
  
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 2vw;
  height: 22vh;
  align-items: center;
  padding-left: 12%;
  
}
.valueTemperatur {
  font-weight: bold;
  /* padding-right: 40%;
  padding-bottom: 0%;
  padding-top: 10%;
  top: 3.6vh; */
  position: absolute;
  top: 5%;
  right: 70%;
}

.value {
  font-weight: bold;
  /* margin-top: 0.8vh;
  padding-left: 0%; */
  position: absolute;
  top: 5%;
  left: 130%;

}
.valuePrecipitation{
  font-weight: bold;
  /* padding-left: 40%;
  width: 4vw;
  margin-top: 2vh; */
  width: 4vw;
  position: absolute;
  bottom: 10%;
  left: 30%;
}
.weatherCloudAndPrecipitation-icon-container{
  /* display: flex;
  flex-direction: column;
  align-items: center; 
  padding-left: 50%;
  margin-top: 0px;
  margin-bottom: 20%;
  width: 2vw;
  height: 11.55vh;
  z-index: 1; */
  width: 2vw;
  position: absolute;
  z-index: 1;
  top: 15%;
  left: -30%;
}

.wind-icon-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; 
  padding-right: 64%;
  margin-top: -2vh;
  z-index: 0; */
  position: absolute;
  z-index: 0;
  width: 6.5vw;
  top: -32%;
  left: 50%;
}
.icon{
  height: 19.444vh;
}


