@font-face {
    font-family: "orsted-font-light";
    src: url("../fonts/OrstedSans-Light.woff") format('woff');
}

@font-face {
    font-family: "orsted-font-black";
    src: url("../fonts/OrstedSans-Black.woff") format('woff');
}

@font-face {
    font-family: "orsted-font-black-italic";
    src: url("../fonts/OrstedSans-BlackItalic.woff") format('woff');
}

@font-face {
    font-family: "orsted-font-bold";
    src: url("../fonts/OrstedSans-Bold.woff") format('woff');
}

@font-face {
    font-family: "orsted-font-bold-italic";
    src: url("../fonts/OrstedSans-BoldItalic.woff") format('woff');
}

@font-face {
    font-family: "orsted-font-italic";
    src: url("../fonts/OrstedSans-Italic.woff") format('woff');
}

@font-face {
    font-family: "orsted-font-light-italic";
    src: url("../fonts/OrstedSans-LightItalic.woff") format('woff');
}

@font-face {
    font-family: "orsted-font-regular";
    src: url("../fonts/OrstedSans-Regular.woff") format('woff');
}

@font-face {
    font-family: "orsted-font-semi-bold";
    src: url("../fonts/OrstedSans-SemiBoldText.woff") format('woff');
}