/* Weather.css */

.Weather {
  background-color: #ffffff;
  color: #000;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.weather-container {
  width: 86vw;
  height: 86vh;
  padding: 7vh 7vw 7vh 7vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.weatherCloudAndPrecipitation-icon {
  width: 8vw;
  padding-top: 70%;
}
.wind-icon {
  width: 6.5vw;
  padding-top: 100%;
}
.noDataForForecast-container{
  position: absolute;
  width: 30vw;
  height: 8vh;
  left: 35vw;
  top: 36vh;
}

.noDataForForecast{
  font-size: 7vh;
}
.Morgen{
  font-size: 1.5vw;
}
.Middag{
  font-size: 1.5vw;
}
.Aften{
  font-size: 1.5vw;
}
.dateMargin{
  font-size: 1.5vw;
  font-weight: bold;
}
.Vejretoverskrift{
  font-size: 3vw;
  position: relative;
  right: -2vw;
}
.vejrDataFraDmi{
  font-size:small;
  padding-left: 90%;
}
