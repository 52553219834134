/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  min-width: 1600px;
  min-height: 900px;
}

.slideshow-new {
  display: absolute;
  margin: 0 auto;
  overflow: hidden;
  min-width: 1600px;
  min-height: 900px;
}
